<template>
  <div :class="s.wrapper">
    <!-- menu items -->
    <div :class="[s.content, isOpen ? s.isOpen : '']">
      <ul :class="s.menuList">
        <li v-for="menu in menuItems" :key="menu.label" :class="s.menuItem">
          <router-link :to="`/${menu.link}`" @click="isOpen = false">{{ menu.label }}</router-link>
        </li>
      </ul>
    </div>
    <!-- button -->
    <button
      :class="[s.btn, isOpen ? s.isOpen : '']"
      @click="isOpen = !isOpen"
    >
      <span :class="[s.bar, isOpen ? s.isOpen : '']"/>
      <span :class="[s.bar, isOpen ? s.isOpen : '']"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'menu-drawer',
  props: {
    menuItems: {
      type: Array,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="scss" module="s">
.wrapper {
  position: fixed;
  bottom: 100%;
  left: 0;
  width: 100%;
}

.btn {
  position: fixed;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 24px;
  height: 6px;
  background-color: var(--label-primary);
  border-radius: 12px;
  transform-origin: center;
  rotate: 0deg;
  transition: all .3s ease-in-out;

  &:nth-child(1) {
    translate: 0 -5px;

    &.isOpen {
      translate: 0 0;
      rotate: 225deg;
    }
  }

  &:nth-child(2) {
    translate: 0 5px;

    &.isOpen {
      translate: 0 0;
      rotate: -225deg;
    }
  }
}

.content {
  backdrop-filter: blur(34px);
  width: 100%;
  padding: var(--md-header-height) 80px 80px;
  translate: 0 0;
  transition: all .5s ease-in-out;
  pointer-events: none;

  &.isOpen {
    translate: 0 100%;
    pointer-events: auto;
  }
}

.menuList {
  padding-top: 40px;
}

.menuItem {
  font-size: 24px;

  & + & {
    margin-top: 40px;
  }
}
</style>
