<template>
  <div class="home">
    <KeyVisual />
    <section :class="s.features">
      <SectionLayout
        id="features"
        title="Features"
        subTitle="MITTの特徴"
        :paddingTop="0"
        :mdPaddingTop="90"
        :mdPaddingBottom="120"
        color="#ffffff">
        <img :class="[s.border, s.top]" src="/img/parts/section_border.svg" alt="">
        <img :class="[s.border, s.bottom]" src="/img/parts/section_border.svg" alt="">
        <div :class="s.badge">
          <div>
            地方都市<br>
            <span>発!!</span>
          </div>
        </div>
        <VerticalList :class="s.section_content">
          <li
            v-for="(item, i) in features" :key="i"
            :class="[s.list_item_box, s.white]">
            <div :class="s.list_item_text">
              <div>
                <div :class="s.list_item_title" v-html="item.title"/>
                <div
                  :class="s.list_item_detail"
                  v-html="item.detail" />
              </div>
              <div
                :class="s.list_item_note"
                v-if="item.note"
                v-html="item.note" />
            </div>
            <div v-if="item.img" :class="[s.list_item_img, s[item.name]]">
              <div>
              <img :src="item.img" alt="">
              </div>
            </div>
          </li>
        </VerticalList>
      </SectionLayout>
    </section>

    <section :class="s.usage">
      <SectionLayout
        id="usage"
        title="Usage"
        subTitle="MITTの使い方"
        :paddingTop="120"
        :mdPaddingTop="120">
        <VerticalList
          :class="s.section_content"
          :spaceY="86"
          :mdSpaceY="40">
          <li
            v-for="(item, i) in usages" :key="i"
            :class="[
              s.gray,
              s.list_item_box,
              i % 2 !== 0 ? s.right : s.left,
            ]">
            <div :class="s.list_item_step">0{{ i + 1 }}</div>
              <div v-if="item.img" :class="[s.list_item_img, s[item.name]]">
                <div>
                  <img :src="item.img" alt="">
                </div>
              </div>
            <div :class="s.list_item_text">
              <div>
                <div :class="s.list_item_title" v-html="item.title"/>
                <div
                  :class="s.list_item_detail"
                  v-html="item.detail" />
                <div
                  :class="s.list_item_note"
                  v-if="item.note"
                  v-html="item.note"/>
              </div>
            </div>
          </li>
        </VerticalList>
      </SectionLayout>
    </section>

    <section :class="s.contents">
      <SectionLayout
        id="contents"
        title="Contents"
        subTitle="SNSや印刷して使えるコンテンツ"
        :paddingTop="120"
        :mdPaddingTop="80"
        :mdPaddingBottom="80">
        <ul :class="s.section_content">
          <li
            v-for="(item, i) in contents" :key="i"
            :class="s.list_item">
            <div :class="s.list_item_title" >
              {{ item.title }}
            </div>
            <img 
              :class="s.list_item_img"
              :src="item.img" alt=""
            />
            <a 
              :class="s.list_link"
              :href="item.link" download>ダウンロード
            </a>
          </li>
        </ul>     
      </SectionLayout>
    </section>

    <section :class="s.start">
      <SectionLayout
        id="introduction"
        title="MITTを導入"
        subTitle="事業者様向け"
        color="white">
        <div :class="[s.start_text, s.section_content]">
          MITTの配車システムは<br>
          ライドシェアの導入を考えている事業者様に導入いただけます。<br>
          導入に費用はかかりません。
        </div>
        <a
          :class="s.start_btn"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdI0n9ufldxXzO282dIZ_Xoo6TUhthgzge_EHo27mejyooUNQ/viewform">資料を請求</a>
      </SectionLayout>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SectionLayout,
  VerticalList,
  KeyVisual,
} from '@/components/parts';

export default {
  name: 'Home',
  components: {
    SectionLayout,
    VerticalList,
    KeyVisual,
  },
  data() {
    return {
      features: [
        {
          name: 'feature_1',
          title: 'LINEで呼べる<span>タクシー</span>',
          detail: 'MITTは専用アプリをダウンロードしなくても、QRコードからLINEのお友達登録するだけですぐにご利用になれます！\nまた、呼び方もとっても簡単!! 「車を呼ぶ」ボタン押せばOK。\nまずは登録してね。',
          img: '/img/QR/line_qr.png',
        },
        {
          name: 'feature_2',
          title: 'マップで直感的に<span>配車</span>',
          detail: '行き先はアプリのマップにピンを指定するだけ！\nわかりやすい画面で行き先の間違えなどの不安はいりません',
          img: '/img/samples/feature_2.png',
        },
        {
          name: 'feature_3',
          title: '料金が事前に<span>わかる</span>',
          detail: 'MITTのライドシェアは事前に料金がわかる(※1)、追加料金なし!！\n交通状況や運転時間での料金変更がないため安心してご利用いただけます。',
          note: '※1 事前確定運賃はライドシェアのみの適応となります。\nタクシーは従来通りのメーター料金になります。',
          img: '/img/samples/feature_3.png',
        },
      ],
      usages: [
        {
          name: 'usage_1',
          title: '<span>QRコードから</span><span>LINEお友達登録</span>',
          detail: 'お使いのスマートフォンで左のQRコードを読み込みます。\nLINEでMITT公式をお友達登録してください。\nMITTアプリはLINE内に組み込まれているため、新たにMITTアプリのインストールは必要ありません。\n※事前にLINEアプリのインストールが必要です。',
          img: '/img/QR/line_qr.png',
        },
        {
          name: 'usage_2',
          title: '<span>ライドシェアを呼ぶ</span>を<span>選択</span>',
          detail: 'アプリ内の「車を呼ぶ」をタップして、ご希望の種別を選択してライドシェアを呼びます。\nライドシェア(※1)は運賃が事前にわかるため、従来のように交通状況などに左右されることなく安心してご利用いただけます。',
          note: '※1 事前確定運賃はライドシェアのみの適応となります。\nタクシーは従来通りのメーター料金になります。',
          img: '/img/samples/usage_2.png',
        },
        {
          name: 'usage_3',
          title: '乗車地点、降車地点を<span>設定</span>',
          detail: 'MITTでは常に最新のマップを直感的に操作して乗車・降車を指定できるため、間違えがなく簡単にご利用いただけます。\nまた、乗り降りの場所をピンポイントで指定できるために、スムーズなお車の利用が可能になっております。',
          img: '/img/samples/usage_3.png',
        },
        {
          name: 'usage_4',
          title: '到着したタクシーに乗車',
          detail: 'お車は到着し次第LINEで連絡がきます。\nご指定の場所においでいただければすぐに目的地まで乗車が可能となります。\nまた、ルート・乗車時間・運賃などがまとめて確認できるため、安心してご利用いただけます。',
          img: '/img/samples/usage_4.png',
        },
      ],
      contents: [
        {
          title: 'パンフレット(PDF)',
          img: 'img/contents/contents_1.png',
          link: '/img/downloads/leaflet-1.pdf',
        },
        {
          title: 'QR-Green',
          img: '/img/contents/contents_2.png',
          link: '/img/downloads/qr-green.png',
        },
        {
          title: 'QR-Blue',
          img: '/img/contents/contents_3.png',
          link: '/img/downloads/qr-blue.png',
        },
        {
          title: 'ロゴ',
          img: '/img/contents/contents_4.png',
          link: '/img/downloads/logo-square.png',
        },
        {
          title: 'ロゴ-円',
          img: '/img/contents/contents_5.png',
          link: '/img/downloads/logo-circle.png',
        },
      ],
    };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  created() {},
  mounted() {
    if (this.$route.hash) this.jump2Hash(this.$route.hash.replace('#', ''));
  },
  watch: {
    $route() {
      if (this.$route.hash) this.jump2Hash(this.$route.hash.replace('#', ''));
    },
  },
  methods: {
    jump2Hash(id) {
      this.$nextTick(() => {
        const target = document.getElementById(id);
        const scrollY = target.getBoundingClientRect().top;
        if (!target) return;
        this.$nextTick(() => {
          window.scrollBy({ top: scrollY, behavior: 'smooth' });
        });
      });
    },
  },
};
</script>


<style lang="scss" module="s">
.section_content {
  margin: 0 auto;
  max-width: 960px;
}

.badge {
  width: 235px;
  height: 235px;
  background-color: var(--label-primary);
  color: var(--label-inverse);
  text-align: center;
  vertical-align: center;
  font-size: 32px;
  letter-spacing: calc(32px * 0.06);
  line-height: 1;
  padding-top: 71.5px;
  border-radius: 150px;

  position: absolute;
  left: 66px;
  top: calc(-1 * 100vw * (215 / 1440 / 2));
  transform: translateY(-50%);

  span {
    display: block;
    font-size: 44px;
    letter-spacing: 2.64px;
    margin-top: 12px;
  }
}

.border {
  position: absolute;
  width: 100%;
  left: 0;
  &.top {
    bottom: 100%;
    transform: rotate(180deg) translateY(-2px);
  }
  &.bottom {
    top: 100%;
    transform: translateY(-2px);
  }
}

.list_item_box {
  padding: 40px 64px;
  border-radius: 32px;
  display: flex;
  position: relative;
  &.white { background-color: var(--bg-white); }
  &.gray { background-color: var(--bg-gray); }

  .list_item_step {
    position: absolute;
    top: 0;
    font-size: 80px;
    font-weight: bold;
    transform: translateY(-50%);
  }
  &.left {
    .list_item_step {
      left: 0; 
    }
  }
  &.right {
    flex-direction: row-reverse;
    .list_item_step {
      right: 0; 
    }
  }
}

.list_item_text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.list_item_title {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 4px;
  > span {
    white-space: nowrap;
  }
}
.list_item_detail {
  margin-top: 22px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1.6px;
  white-space: pre-wrap;
}

.list_item_note {
  margin-top: 32px;
  white-space: pre-wrap;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1.6px;
  font-size: 10px;
}

// セクションごとに個別に指定する項目
.features {
  background-color: var(--surface-primary);
  position: relative;
  .list_item_box {
    padding-bottom: 0;
  }
  .list_item_note {
    padding-bottom: 40px;
  }
  .list_item_img {
    margin-left: 40px;
    width: 30%;

    > * {
      padding: 14px 4px 0;
      border-radius: 20px 20px 0 0;
      box-shadow: rgba(#000000, 0.12) 0 -4px 22px 0;
      > img {
        width: 100%;
        border-radius: 10px 10px 0 0;
      }
    }

    &.feature_1 {
      > * {
        padding-bottom: 80px;
        > img {
          display: block;
          margin: 22px auto 0;
          max-width: 140px;
          border: 8px solid var(--line-green);
          border-radius: 12px;
        }
      }
    }
  }
}

.usage {
  background-color: var(--bg-gray);
  padding-top: calc(100vw * (215 / 1440));
  .list_item_box {
    align-items: center;
  }
  .left {
    .list_item_img {
      margin-right: 40px;
      margin-left: 40px;
      &.usage_1 {
        margin-left: 0;
      }
    }
  }
  .right {
    .list_item_img {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  .list_item_img {
    width: 20%;
    > * {
      padding: 14px 4px 18px;
      border-radius: 20px;
      background-color: var(--bg-white);
      box-shadow: rgba(#000000, 0.12) 0 -4px 22px 0;
      > img {
        width: 100%;
        border-radius: 10px 10px 0 0;
      }
    }

    &.usage_1 {
      width: auto;
      > * {
        padding: 0 0;
        > img {
          padding: 10px;
          display: block;
          margin: 0 auto;
          max-width: 160px;
          border: 8px solid var(--line-green);
          border-radius: 12px;
        }
      }
    }
  }
}

.contents{
  background-color: rgba(22, 56, 72, 0.1);
  
  .section_content{
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .list_item{
    width: 304px;
    background-color: rgba(255, 255, 255, 0.48);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 345px;
    border-radius: 32px;
    padding: 40px 0;
  }
  .list_item_title{
    font-weight: bold;
    font-size: 20px;
  }
  .list_item_img{
    height: 160px;
  }
  .list_link{
    background-color: rgb(27, 152, 208);
    border-radius: 150px;
    padding: 6px 24px;
    width: 168px;
    color: var(--label-inverse);
    font-size: 20px;
  }
  @include mdView{
    .list{
      gap:16px
    }
    .list_item{
      padding: 32px 0;
      width: 294px;
    }
    .list_item_title{
      font-size: 18px;
    }
    .list_link{
      width: 156px;
      font-size: 18px;
    }  
  }
}


.start {
  background-color: var(--label-primary);
  padding-bottom: calc(100vw * (314 / 1440) * (1 - 0.28));
}

.start_text {
  color: var(--label-inverse);
  font-size: 28px;
  text-align: center;
  line-height: 1.86;
  letter-spacing: 2.8px;
}
.start_btn {
  display: block;
  width: fit-content;
  padding: 18px 80px;
  background-color: var(--bg-white);
  font-size: 32px;
  border-radius: 150px;
  margin: 80px auto 0;
}

@include mdView {
  .section_content {
    padding: 0 20px;
  }
  .badge {
    width: 160px;
    height: 160px;
    font-size: 22px;
    padding-top: 52px;
    span {
      font-size: 26px;
    }
  }

  .list_item_box {
    padding: 40px;
    .list_item_step {
      font-size: 48px;
    }
  }
  .list_item_title {
    font-size: 24px;
    letter-spacing: 2.4px;
  }

  .start_text {
    font-size: 16px;
  }
  .start_btn {
    padding: 14px 64px;
    color: var(--label-primary);
    background-color: var(--bg-white);
    font-size: 20px;
    border-radius: 150px;
    margin: 40px auto 0;
  }
}

@include smView {
  .list_item_box {
    &.right {
      flex-direction: column;
    }
    flex-direction: column;
  }
  .badge {
    left: -20px;
  }
  .list_item_title {
    text-align: center;
    > span {
      white-space: nowrap;
    }
  }
  .features {
    .list_item_img {
      margin: 32px auto 0;
      min-width: min(320px,90%);
    }
  }
  .usage {
    .list_item_box {
      flex-direction: column-reverse;
    }
    .list_item_img {
      margin-top: 32px;
      min-width: min(200px,90%);  
    }
    .left {
      .list_item_img {
        &.usage_1 {
          min-width: auto;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
